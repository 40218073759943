* {
    box-sizing: border-box;
    margin: 0;
}


body {
    color: white;
    font-family: 'Share Tech Mono', monospace;
    background-image: url('./images/Abstract2.png');
    background-attachment: fixed;
}

h3 {
    color: rgb(255, 104, 255);
}

@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

.main-body {
    z-index: 1;
}

.background-video {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%;
    object-fit: cover;
}

.title-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title-box p {
    margin: 4px;
    font-size: 22px;
    color: rgb(255, 104, 255);
    letter-spacing: .46rem;
}

.site-title {
    font-size: 46px;
    color: #fff;
    text-decoration: none;
}

.nav {
    display: flex;
    margin-top: 3rem;
    gap: 2rem;
    padding: 0 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    font-size: 1.2rem;
    top: 0;
    z-index: 998;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    margin: 0 auto;
    padding: 0 50px;
}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
}

.nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    text-decoration: none;
    height: 100%;
    transition: all 0.3s ease;
}

.nav-links:hover {
    color: rgb(255, 104, 255);
    transition: all 0.3s ease;
}

.box {
    margin: 2rem 11rem;
    padding: 2rem;
    border-radius: 10px;
    background-color: rgb(11, 36, 75, 0.8);
    display: flex;
    flex-direction: column;
    animation: fadeInAnimation ease 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.box h2 {
    margin-bottom: 1rem;
}

.about-content {
    display: flex;
}

.about-image {
    width: 200px;
    border-radius: 10%;
}

.about-text {
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
}

.about-text a {
    color: rgb(255, 104, 255);
    text-decoration: none;
}

.about-text a:hover {
    text-decoration: underline;
}

.portfolio-item {
    display: flex;
    flex-direction: row;
    align-items: start;
    padding-bottom: 2rem;
    padding-top: 2rem;
    border-bottom: 2px solid white;
}

.portfolio-text a {
    color: rgb(255, 104, 255);
    text-decoration: none;
}

.portfolio-text a:hover {
    text-decoration: underline;
}

.portfolio-item:last-child {
    border: none;
}

.portfolio-item:first-child {
    padding-top: 0px;
}

.portfolio-img {
    width: 450px;
    border-radius: 10px;
    margin-right: 2rem;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.name, .email {
    margin-top: .5rem;
    margin-bottom: .5rem;
    width: 30%;
    padding: 6px;
}

.message {
    margin-top: .5rem;
    margin-bottom: .5rem;
    padding: 6px;
}

.send-btn {
    background-color: rgb(255, 104, 255);
    color: #fff;
    font-family: 'Share Tech Mono', monospace;
    font-size: 18px;
    border-width: 0px;
    border-style: none;
    padding: .5rem;
    margin-top: .5rem;
    width: 200px;
    border-radius: 10px;
}

.send-btn:hover {
    background-color: #fff;
    color: rgb(255, 104, 255);
    cursor: pointer;
}

.gallery {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-box {
    width: 1300px;
}

.gallery-image {
    width: 300px;
    height: 300px;
    object-fit: cover;
    margin: 5px;
    border-radius: 10px;
}

.gallery-image:hover {
    cursor: pointer;
    filter: brightness(50%);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 27, 0.541);
    display: flex;
    align-items: center;
  }
  .overlay img {
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  }
  .overlay > span {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
  }

  @media screen and (max-width: 960px) {
    .background-video {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }

    .title-box {
        display: flex;
        justify-content: center;
        padding: 1rem;
        width: 60%;
    }

    .nav {
        background: #2a4c7d;
        padding: 0;
        position: fixed;
        width: 100%;
        height: rem;
        top: 0;
        z-index: 999;
        margin-top: 0;
    }

    .nav-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 80px;
        z-index: 1;
        width: 100%;
        max-width: 1300px;
        padding: 0;
        margin-left: 0px;
    }
    .site-title {
        font-size: 30px;
    }

    .title-box p {
        font-size: 12px;
        letter-spacing: auto;
    }
    
    .nav-menu {
        display: grid;
        grid-template-columns: auto;
        margin: 0;
        width: 100%;
        position: absolute;
        top: -1000px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: -1;
    }

    .nav-menu.active {
        background: #2a4c7d;
        top: 100%;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 99;
        font-size: 1.6rem;
        margin-left: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .navbar-toggle .bar {
        width: 25px;
        height: 3px;
        margin: 5px auto;
        transition: all 0.3s ease-in-out;
        background: #fff;
    }
    
    .nav-item {
        width: 100%;
    }
    
    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    
      #mobile-menu {
        position: absolute;
        top: 20%;
        right: 5%;
        transform: translate(5%, 20%);
      }
    
      .navbar-toggle .bar {
        display: block;
        cursor: pointer;
      }
    
      #mobile-menu.is-active .bar:nth-child(2) {
        opacity: 0;
      }
    
      #mobile-menu.is-active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
      }
    
      #mobile-menu.is-active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
      }

      .box {
        margin-top: 10rem;
        margin-left: 1.3rem;
        width: 90%;
        padding: 1rem;
        justify-content: center;
      }

      .about-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding: 1rem;
      }

      .about-image {
        width: 90%;
      }
      .about-text {
        margin-top: 1rem;
      }

      .portfolio-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
      }

      .portfolio-item a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .portfolio-img {
        width: 90%;
        padding: 1rem;
      }

      .gallery-image {
        width: 75px;
        height: 75px;
      }

      .overlay img {
        max-width: 80%;
        max-height: 80%;
        margin: 20px auto;
      }

      .gallery-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 1rem;
      }

      .name, .email {
        width: 100%;
      }

      .overlay > span {
        top: 10rem;
      }
    }

